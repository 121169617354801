import React, {useState, useEffect, useContext}  from "react";
import { useParams, useLocation } from "react-router-dom";

// Section for Utilities and Context
import "./profile.css";
import API from "../utils/API";
import { AuthContext } from "../utils/AuthContext";

// Section for Components
import ProfileCard from "../components/ProfileCard";
import BioBox from "../components/BioBox";
import JobList from "../components/JobList";
import ColleagueList from "../components/ColleagueList";
// import StaffedBills from "../components/StaffedBills";
import ProfileIssues from "../components/ProfileIssues";
import AddJobModal from "../components/AddJobModal";
import EditProfileModal from "../components/EditProfileModal";


export default function Profile() {
    const [singleProfile, setSingleProfile] = useState();
    const { auth, setAuth } = useContext(AuthContext);
    const { id } = useParams();
    const location = useLocation();

    // console.log(singleProfile.data)

    useEffect(() => {
        // GET SINGLE PROFILE INFO FROM DATABASE FOR PROFILE PAGE
        const getProfile = async () => {        
            try {
                const data = await API.getSingleProfile(id); 
                // console.log("Fetched Profile Data:", data.data);
                setSingleProfile(data);
            } catch (error) {
                console.log("Error fetching single profile:", error);
            }
        };

        getProfile();
    }, [id]);  

    // GET SINGLE PROFILE INFO FROM DATABASE FOR PROFILE PAGE
    const fetchSingleProfile = async () => {        
        try {
            const data = await API.getSingleProfile(id); 
            // console.log("Fetched Profile Data:", data.data);
            setSingleProfile(data);
        } catch (error) {
            console.log("Error fetching single profile:", error);
        }
    };
        
    
    // HANDLE NULL STATE PRIOR TO RENDERING CORRECTLY
    if (!singleProfile) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="row profile-row">
                <div className="col-xxl-3 col-md-4 col-sm-12 profile-left-column">
                    <div className="profile-card">
                        <ProfileCard
                            index={singleProfile.data.profile_id}
                            user_id={singleProfile.data.user_id}
                            firstName={singleProfile.data.first_name}
                            lastName={singleProfile.data.last_name}
                            title={singleProfile.data.JobExperiences[0]?.title || "Not Available"}
                            organization={singleProfile.data.JobExperiences[0]?.Division?.division_name || singleProfile.data.JobExperiences[0]?.Organization?.organization_name || "Not Available"}
                            profile_picture={singleProfile.data.profile_picture}
                            email={singleProfile.data.work_email}
                            phone={singleProfile.data.other_phone}
                            address={singleProfile.data.JobExperiences[0]?.street_address || singleProfile.data.JobExperiences[0]?.Organization?.street_address || null}
                            suite_number={singleProfile.data.JobExperiences[0]?.suite_number || singleProfile.data.JobExperiences[0]?.Division?.suite_number || singleProfile.data.JobExperiences[0]?.Organization?.suite_number || null}
                            city={singleProfile.data.JobExperiences[0]?.city || singleProfile.data.JobExperiences[0]?.Organization?.city || null}
                            stateCode={singleProfile.data.JobExperiences[0]?.state || singleProfile.data.JobExperiences[0]?.Organization?.state || null}
                            zipCode={singleProfile.data.JobExperiences[0]?.zip_code || singleProfile.data.JobExperiences[0]?.Organization?.zip_code || null}
                            website={singleProfile.data.JobExperiences[0]?.Division?.website || singleProfile.data.JobExperiences[0]?.Organization?.website || null} 
                            linkedin_url={singleProfile.data.linkedin_url || null}
                            facebook_url={singleProfile.data.facebook_url || null}
                            youtube_url={singleProfile.data.youtube_url || null}
                            threads_url={singleProfile.data.threads_url || null}
                            twitter_url={singleProfile.data.twitter_url || null}
                            instagram_url={singleProfile.data.instagram_url || null}
                            tiktok_url={singleProfile.data.tiktok_url || null}
                            pronouns={singleProfile.data.pronouns || null}
                            />
                    </div>
                    {/* <div className="profile-ad-box">
                        <AdBox />
                    </div> */}
                </div>
                <div className="col-xxl-4 col-md-7 col-sm-12 profile-middle-column">
                    <div className="bio-box">
                        <BioBox 
                            key={"bio" + singleProfile.data.last_name + singleProfile.data.profile_id}
                            profile_id={singleProfile.data.profile_id}
                            firstName={singleProfile.data.first_name}
                            bio={singleProfile.data.user_bio}
                            user_id={singleProfile.data.user_id}
                            reRenderProfile={fetchSingleProfile}
                        />
                    </div>
                    <div className="profile-job-list">
                        <JobList 
                            job_id={singleProfile.data.JobExperiences.job_id}
                            last_name={singleProfile.data.last_name}
                            profile_id={singleProfile.data.profile_id}
                            experience={singleProfile.data.JobExperiences}
                            user_id={singleProfile.data.user_id}
                            current={singleProfile.data.current_job}
                            reRenderProfile={fetchSingleProfile}
                        />
                        <AddJobModal 
                            profile_id={singleProfile.data.profile_id}
                            reRenderProfile={fetchSingleProfile}
                        />
                    </div>
                </div>
                <div className="col-xxl-3 col-md-7 col-sm-12 profile-right-column">
                    <div className="profile-colleague-list">
                        <ColleagueList 
                            profile_id={singleProfile.data.profile_id}
                            colleague_team={
                                singleProfile.data.JobExperiences[0]?.Division?.division_name || 
                                singleProfile.data.JobExperiences[0]?.Organization?.organization_name || 
                                "Not Available"}
                            organization_id={
                                singleProfile.data.JobExperiences[0]?.Organization?.organization_id || 
                                null
                            }
                            division_id={
                                singleProfile.data.JobExperiences[0]?.Division?.division_id || 
                                null
                            }
                            />
                    </div>
                    <div className="profile-bill-history">
                        <ProfileIssues 
                            issues={singleProfile.data.UserIssues || "None"}
                            user_id={singleProfile.data.user_id}
                            profile_id={singleProfile.data.profile_id}
                            reRenderProfile={fetchSingleProfile}
                        />
                        {/* <StaffedBills /> */}
                    </div>
                </div>
            </div>
            <div className="row last-row"/>
            
            <EditProfileModal
                data={singleProfile.data}
                profile_picture={singleProfile.data.profile_picture}
                reRenderProfile={fetchSingleProfile}
            />
        </>
    )
}