import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import DOMPurify from 'dompurify';

// Section for Utilities and Context
import "./postcard.css";
import API from "../utils/API";
import { AuthContext } from "../utils/AuthContext";

// Section for imported components 
import PostComment from "./PostComment";

// PostCardBody component
const PostCardBody = ({ body }) => {
    const MAX_CHARACTERS = 300;

    // Truncate body text while preserving HTML
    const truncateHtml = (html, maxLength) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
        const plainText = tempDiv.textContent || tempDiv.innerText || "";
        
        if (plainText.length <= maxLength) return html;

        const truncatedText = plainText.substring(0, maxLength) + "...";
        tempDiv.textContent = truncatedText;
        return tempDiv.innerHTML;
    };

    const sanitizedBody = DOMPurify.sanitize(body, {
        ADD_ATTR: ['target', 'rel'],
    });

    const truncatedBody = truncateHtml(sanitizedBody, MAX_CHARACTERS);

    return (
        <div 
            className="card-text" 
            dangerouslySetInnerHTML={{ __html: truncatedBody }}
        />
    );
};


export default function PostCard(props) {
    const { auth, setAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [postTitle, setPostTitle] = useState("");
    const [postBody, setPostBody] = useState("");
    const [likedPost, setLikedPost] = useState(false);
    const [usersLikedPosts, setUsersLikedPosts] = useState([]);
    const [likeCount, setLikeCount] = useState();
    const [postType, setPostType] = useState([]);
    const [eventType, setEventType] = useState([]);
    const [singleDivision, setSingleDivision] = useState([]);
    const [singleOrganization, setSingleOrganization] = useState([]);
    const [existingLike, setExistingLike] = useState(false);
    const [readCount, setReadCount] = useState(props.reads);
    const [postEditing, setPostEditing] = useState(false);
    const [commentEditing, setCommentEditing] = useState(false);
    const [commentInput, setCommentInput] = useState("");
    const [commentCount, setCommentCount] = useState();
    const [editCommentInput, setEditCommentInput] = useState("");
    const [profileData, setProfileData] = useState([]);
    const [eventLocationEntry, setEventLocationEntry] = useState("");
    const [eventDateEntry, setEventDateEntry] = useState(undefined);
    const [startTimeEntry, setStartTimeEntry] = useState(undefined);
    const [endTimeEntry, setEndTimeEntry] = useState(undefined);

    // console.log("PostCard props : ", props.comments)

    const handleNavigate = (profile_id) => {
        navigate("/profile/" + profile_id);
    }

    // GET INFO FROM DATABASE FOR SINGLE POST
    useEffect(() => {

        // GET POST TYPE INFO FROM DATABASE 
        const fetchPostType = async () => {        
            try {
                const data = await API.getSinglePostType(props.post_type); 
                // console.log("Fetched Profile Data:", data.data);
                setPostType(data.data);
            } catch (error) {
                console.log("Error fetching single profile:", error);
            }
        };

        // GET POST TYPE INFO FROM DATABASE 
        const fetchEventType = async () => {        
            try {
                const data = await API.getSingleEventType(props.event_type); 
                // console.log("Fetched Event Type Data:", data.data);
                setEventType(data.data);
            } catch (error) {
                console.log("Error fetching single profile:", error);
            }
        };
        // GET DIVISION NAME FROM DATABASE
        const fetchDivisionName = async () => {        
            try {
                const data = await API.getSingleDivisionName(props.div_id); 
                // console.log("Fetched Profile Data:", data.data);
                setSingleDivision(data.data);
            } catch (error) {
                console.log("Error fetching single profile:", error);
            }
        };

        // GET ORGANIZATION NAME FROM DATABASE
        const fetchOrganizationName = async () => {        
            try {
                const data = await API.getSingleOrganizationName(props.org_id); 
                // console.log("Props Org ID: ",props.org_id,"\nFetched Single Org Data:", data.data);
                setSingleOrganization(data.data);
            } catch (error) {
                console.log("Error fetching single profile:", error);
            }
        };

        // GET USER LIKES FROM DATABASE
        const fetchUserLikes = async () => {       
            if (!auth.user) return;

            try {
                const data = await API.getUserLikes(auth.user?.user_id); 
                setUsersLikedPosts(data.data);

                // Check if the current post_id is in the liked posts
                const liked = data.data.some(post => post.post_id === props.post_id && post.liked === true);
                const existing = data.data.some(post => post.post_id === props.post_id);
                setLikedPost(liked);
                setExistingLike(existing);
            } catch (error) {
                console.log("Error fetching user likes:", error);
            }
        };

        const fetchProfileData = async () => {      
            if (!auth.user) {
                // Handle public view case
                setProfileData(null);
                return;
            }

            try {
                const id = auth.user?.user_id;
                const data = await API.getProfileDataForPost(id); 
                // console.log("Fetched Profile Info:", data.data);
                setProfileData(data.data);
            } catch (error) {
                console.log("Error fetching profile info:", error);
            }
        };

        const commentCount = props.comments ? props.comments.length : 0;
        setCommentCount(commentCount);

        fetchUserLikes();
        fetchPostType();
        fetchEventType();
        fetchDivisionName();
        fetchOrganizationName();
        fetchProfileData();

        setLikeCount(props.likes);

        setPostTitle(props.title);
        setPostBody(props.body);
        setEditCommentInput(props.comments.comment_body);
        setEventLocationEntry(props.event_location);
        setEventDateEntry(props.event_date);
        setStartTimeEntry(props.event_start || "");
        setEndTimeEntry(props.event_end || "");

    }, [props]); 

    const handlePostDate = (datetime) => {
        const date = new Date(datetime);
    
        // Adjust for timezone offset
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

        return date.toDateString();
    };

    const handlePostTime = (time) => {
        // Split the time string into hours, minutes, and seconds
        let [hours, minutes] = time.split(':');

        // Convert hours from string to number
        hours = parseInt(hours, 10);

        // Determine the AM/PM period
        const period = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        hours = hours % 12 || 12; // Convert '0' to '12' for midnight

        // Return the formatted time
        return `${hours}:${minutes} ${period}`;
    };

    const handleLike = async () => {
        // NEED TO CALL THE LOGIN COMPONENT HERE !!! <<<<<<<<<
        if (!auth.user) {
            console.log("Login required to like posts.");
            return;
        }

        try {
            // Calculate the new like count
            const newLikeCount = likedPost ? likeCount - 1 : likeCount + 1;
    
            // Update the state
            setLikeCount(newLikeCount);
            setLikedPost(prevLikedPost => !prevLikedPost);
    
            const data = {
                post_id: props.post_id,
                likes_count: newLikeCount,
                liked: !likedPost,
                user_id: auth.user?.user_id,
            };
    
            const response = await API.addNewLike(data);
            console.log(response.data);
        } catch (error) {
            console.error("Server request failed: ", error);
            setLikeCount(likedPost ? likeCount + 1 : likeCount - 1);
            setLikedPost(prevLikedPost => !prevLikedPost);
        }
    };

    const handleComment = async () => {
        // NEED TO CALL THE LOGIN COMPONENT HERE !!! <<<<<<<<<
        if (!auth.user) {
            console.log("Login required to comment.");
            return;
        }


        try {

            if (commentInput !== "") {
                const data = {
                    post_id: props.post_id,
                    comment_author: profileData[0].profile_id,
                    comment_body: commentInput,
                    user_id: auth.user?.user_id
                } 

                const response = await API.addNewComment(data);
                props.refresh();
                setCommentInput("");
                console.log("Post updated successfully:", response.data);
            } else {
                console.log("No comment provided")
                return;
            }           
            
        } catch(error) {
            console.error("Server request failed: ",error);
        }
    }

    const updatePost = async () => {
        try {
            const data = {
                post_id: props.post_id,
                post_title: postTitle,
                post_body: postBody
            }

            if (postType.post_type === "EVENT") {
                data.event_date = eventDateEntry;
                data.event_start = startTimeEntry;
                data.event_end = endTimeEntry;
            }

            const response = await API.updatePost(data);
            props.refresh();
            console.log("Post updated successfully:", response.data);
            
        } catch(error) {
            console.error("Server request failed: ",error);
        }
    };

    const handleEditPost = () => {
        setPostEditing(prevEditPost => !prevEditPost);

        if (postEditing) {
            updatePost();
        }
    };
    
    const addRead = async () => {
        const newReadCount = readCount + 1;
        setReadCount(newReadCount);

        try {
            const data = {
                post_id: props.post_id,
                reads: newReadCount,
            } 

            const response = await API.updateReadCount(data);
            console.log("Like count updated successfully:", response.data);
  
        } catch(error) {
            console.error("Server request failed: ",error);
        }
    };

    // HANDLER FOR DELETING A USER'S POST
    const deletePost = async () => {
        try {
            const data = {
                post_id: props.post_id
            }
            
            const response = await API.deletePost(data);
            props.refresh();
            console.log("Job experience deleted successfully:", response.data);

        } catch(error) {
           console.error("Server request failed: ",error);
        }        
    };

    return (
        <>              
            <div className="card post-card mb-4" key={props.post_id}>
                <div className="card-header post-card-header" >
                    <h6 className="post-type mt-2">{postType.post_type}{postType.post_type === "EVENT" && (` - ${eventType.event_type}`)}</h6>
                    <h5 className="post-title pt-1 display-6">{postTitle}</h5>
                    <p className="post-byline mb-0"><a className="post-author" onClick={() => handleNavigate(props.author_id)}>{props.author}</a> | <i>{props.div_id ? singleDivision?.division_name : singleOrganization.organization_name}</i> | {handlePostDate(props.postDate)}</p>
                </div>
                <div className="card-body pt-0">
                    <PostCardBody body={postBody} />
                    <div className="row mb-0 mt-3">
                        <div className="col-7">
                            <div className="post-likes ms-2">
                                <button 
                                    className={`bi post-likes-heart ${likedPost === true ? 'bi-heart-fill' : 'bi-heart'}`} 
                                    onClick={auth.user ? handleLike : null}
                                    disabled={!auth.user}
                                /> {likeCount}
                                <span 
                                    className={`bi ms-3 comment-count bi-chat-text`} 
                                /> {commentCount}
                            </div>
                        </div>
                        <div className="col-4">
                            <button type="button" className="btn read-more-button " data-bs-toggle="modal" data-bs-target={`#readPostModal` + props.post_id} onClick={addRead}><i className="bi bi-book"> </i>Keep Reading</button>
                        </div>
                    </div>
                </div>
                
                <div className="card-footer my-0 pt-0">
                    <div className="input-group input-group-sm mb-3 post-comment-div">
                        <input type="text" 
                            className="form-control rounded-end post-add-comment ms-2" 
                            aria-label="Sizing example input" 
                            aria-describedby="inputGroup-sizing-sm" 
                            placeholder="Add comment..."
                            value={commentInput}
                            disabled={!auth.user}
                            onChange={(e) => setCommentInput(e.target.value)}
                        />
                        <span><i className="bi bi-plus-circle submit-comment-icon my-2 ms-3" onClick={handleComment}></i></span>
                    </div>
                </div>
            </div>


            {/* Modal to read a full post */}
            <div className="modal fade read-post-modal" key={"post" + props.post_id} id={`readPostModal`+ props.post_id} tabIndex="-1" aria-labelledby="readPostModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content post-modal-content position-absolute top-50 start-50 translate-middle-x">
                        <div className="modal-header post-modal-header">
                            {!postEditing && (
                                <h5 className="modal-title post-modal-title display-6">{postTitle}</h5>
                            )}
                            {postEditing && (
                                <input 
                                    className="post-title-input form-control py-1" 
                                    value={postTitle}
                                    onChange={(e) => setPostTitle(e.target.value)}
                                ></input>
                                
                            )}
                            
                        </div>
                        <div className="modal-body">
                            <div className="row mb-0">
                                <div className="col-10">
                                    <p className="post-byline mb-0"><a className="post-author" data-bs-dismiss="modal" onClick={() => handleNavigate(props.author_id)}>{props.author}</a> | <i>{props.div_id ? singleDivision?.division_name : singleOrganization.organization_name}</i> | {handlePostDate(props.postDate)}</p>
                                </div>
                                <div className="col-2">
                                    <div className="post-likes">
                                        <span><button className={`bi post-likes-heart ${likedPost === true ? 'bi-heart-fill' : 'bi-heart'}`} 
                                         onClick={auth.user ? handleLike : null}
                                         disabled={!auth.user}></button> {likeCount} </span>
                                        {props.user_id === auth.user?.user_id && (
                                            <span><i className={`bi edit-post-button ms-2 ${postEditing === false ? 'bi-pencil-square ' : 'bi-floppy2-fill'}`} onClick={() => handleEditPost()}></i></span>
                                        )}
                                        
                                    </div>  
                                </div>
                            </div>
                            <br/>
                            {!postEditing && (
                                <>
                                <div 
                                    className="card-text post-body mb-4 pre-wrap"
                                    dangerouslySetInnerHTML={{ 
                                        __html: DOMPurify.sanitize(postBody, {
                                            ADD_ATTR: ['target', 'rel']
                                        }) 

                                    }}
                                />
                                {postType.post_type === "EVENT" && (
                                    <>
                                        <span className="post-event-date">Location: {eventLocationEntry}</span><br/>
                                        <span className="post-event-date">Date: {handlePostDate(eventDateEntry)}</span><br/>
                                        <span className="post-event-date">Time: {handlePostTime(startTimeEntry)} to {handlePostTime(endTimeEntry)}</span>
                                    </>                                        
                                )}
                            </>                             

                            )}
                            {postEditing && (
                                <>                                    
                                    {/* <textarea 
                                        className="post-body-textarea form-control" 
                                        value={postBody}
                                        onChange={(e) => setPostBody(e.target.value)}
                                    ></textarea> */}
                                    <div>
                                        <ReactQuill
                                            className="form-control mb-2 p-1 post-body-input" 
                                            value={postBody}
                                            onChange={setPostBody}
                                            placeholder="Write your post..."
                                            modules={{
                                                toolbar: [
                                                    ['bold', 'italic', 'underline'],       // Text formatting
                                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                                    ['link'],                             // Link for URL/email
                                                    ['clean']                             // Clear formatting
                                                ],
                                            }}
                                            formats={['header', 'bold', 'italic', 'underline', 'list', 'bullet', 'link']}
                                        />
                                    </div>
                                    {postType.post_type === "EVENT" && (
                                        <>
                                            <div className="row mt-3">
                                                <div className="input-group input-group-sm">
                                                    <span className="input-group-text post-event">Location: </span>
                                                    <input 
                                                        type="text" 
                                                        className="form-control  edit-event-location" 
                                                        aria-label=""  
                                                        value={eventLocationEntry}
                                                        onChange={(e) => {setEventLocationEntry(e.target.value)}}
                                                        required
                                                    />
                                                
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="input-group input-group-sm">
                                                    <span className="input-group-text post-event">Date: </span>
                                                    <input 
                                                        type="date" 
                                                        className="form-control  edit-event-date" 
                                                        aria-label=""  
                                                        value={eventDateEntry}
                                                        onChange={(e) => {setEventDateEntry(e.target.value)}}
                                                        required
                                                    />
                                                </div>

                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-4">
                                                    <div className="input-group input-group-sm">
                                                        <span className="input-group-text post-event">From: </span>
                                                        <input 
                                                            type="time" 
                                                            className="form-control edit-event-time" 
                                                            aria-label=""
                                                            value={startTimeEntry}
                                                            onChange={(e) => {setStartTimeEntry(e.target.value)}}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="input-group input-group-sm">
                                                        <span className="input-group-text post-event">Until: </span>
                                                        <input 
                                                        type="time" 
                                                        className="form-control edit-event-time" 
                                                        aria-label=""  
                                                        value={endTimeEntry}
                                                        onChange={(e) => {setEndTimeEntry(e.target.value)}}
                                                        required
                                                        />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </>                                        
                                    )}                                    
                                </>
                            )}
                            <div ><hr/></div>
                                <div className="input-group input-group-sm mb-3 post-comment-div">
                                    <input type="text" 
                                        className="form-control rounded-end post-add-comment ms-2" 
                                        aria-label="Sizing example input" 
                                        aria-describedby="inputGroup-sizing-sm" 
                                        placeholder="Add comment..." 
                                        value={commentInput}
                                        disabled={!auth.user}
                                        onChange={(e) => setCommentInput(e.target.value)}
                                    />
                                    <span><i className="bi bi-plus-circle submit-comment-icon my-2 ms-3 me-2" onClick={handleComment}></i></span>
                                </div>
                            {props.comments
                            .filter(comment => comment.post_id === props.post_id)
                            .map((comment, index) => (                                
                                <PostComment 
                                    comment_id={comment.comment_id}
                                    profile_id={comment.ProfileInfo.profile_id}
                                    first_name={comment.ProfileInfo.first_name}
                                    last_name={comment.ProfileInfo.last_name}
                                    comment_body={comment.comment_body}
                                    user_id={comment.user_id}
                                    createdAt={comment.createdAt}
                                    updatedAt={comment.updatedAt}   
                                    refresh={props.refresh}                                 
                                />
                            ))}
                            

                        </div>
                        <div className="modal-footer pt-0">
                            {props.user_id === auth.user?.user_id && (
                                <span className="post-delete" data-bs-dismiss="modal" 
                                    onClick={deletePost}
                                ><i className="bi bi-trash3"/>
                                </span>
                            )}
                            <button type="button" className="btn modal-close" data-bs-dismiss="modal" alt="Button to close modal">Close</button>                            
                        </div>
                    </div>
                </div>
            </div>
          
        </>
    )
}