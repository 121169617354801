import React, { useState, useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import {jwtDecode} from "jwt-decode";

// Section for Utilities and Context
import API from "../utils/API";
import './loginModal.css';
import { AuthContext } from "../utils/AuthContext";

// Section for Components
import RequestPasswordReset from "./RequestPasswordReset";
import RegisterModal from "./RegisterModal";

// Local Functions


// MAIN FUNCTION
// NOTE: need to figure out redirect when a user logs in
export default function LoginModal() {
    const [credentials, setCredentials] = useState({"email": "", "password": ""});
    const { auth, setAuth } = useContext(AuthContext);
    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();
    let tokenExpirationTimeout;


    const submitLoginInfo = async () => {
        try {
            const res = await API.login(credentials);
            
            // Set headers for future API calls
            axios.defaults.headers.common["x-auth-token"] = res.data.user.token;

            // Decode the token to get the expiration time
            const decodedToken = jwtDecode(res.data.user.token);
            const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds

            // Set a timeout to log out the user when the token expires
            tokenExpirationTimeout = setTimeout(() => {
                handleLogout();
            }, expirationTime - Date.now());
      
            setAuth({
              ...auth,
              isAuthenticated: true,
              loading: false,
              token: res.data.user.token,
              user: res.data.user,
            });

            setLoggedIn(true);
            // Conditionally navigate based on profile_id
            if (res.data.user.profile_id) {
                navigate("/public-square");
            } else {
                navigate("/profile/create");
            }
            
          } catch (error) {
            // Check if the error is due to email not being confirmed
            if (error.response && error.response.status === 401) {
                const errorMsg = error.response.data.errors[0].msg;

                // Check if it's the email confirmation error
                if (errorMsg === "Please confirm your email to log in") {
                    alert("Please confirm your email before logging in.");
                } else {
                    alert("Invalid credentials, please try again");
                }
            } else {
                alert("An error occurred. Please try again later.");
            }
            setAuth({ ...auth, isAuthenticated: false, token: null });
            console.error(error);
            setCredentials({"email": "", "password": ""});
            
        } 
    };

    const handleLogout = () => {
        // Clear the token from localStorage or wherever it's stored
        localStorage.removeItem('token');
        axios.defaults.headers.common["x-auth-token"] = null;
    
        // Clear the auth state
        setAuth({
          ...auth,
          isAuthenticated: false,
          token: null,
          user: null,
        });
    
        setLoggedIn(false);
        navigate("/");
    };

    const handleKeyPress = (e) => {
        const modal = document.getElementById("loginModal");
        const closeModal = modal.querySelector('.login-close');
            
        if (e.key === "Enter") {
            submitLoginInfo();
            closeModal.click();
        }
    };

    const onChange = (e) => {
        setCredentials({...credentials, [e.target.name]: e.target.value});
    };

    useEffect(() => {
      return () => {
        // Clear the timeout when the component unmounts to avoid memory leaks
        if (tokenExpirationTimeout) {
          clearTimeout(tokenExpirationTimeout);
        }
      };
    }, []);

    // if (loggedIn){
    //     return <Navigate to="/public-square" replace={true}/>;
    // };

    return (
       <>
        <div className="modal fade" id="loginModal" tabIndex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-4 display-6" id="loginModalLabel">Please login.</h1>
                </div>
                <div className="modal-body">
                    <div className="input-group job-date-input py-1">
                        <span className="input-group-text login-span">Email</span>
                        <input 
                            type="text" 
                            className="form-control" 
                            name="email"
                            value={credentials.email}
                            onChange={(e) => onChange(e)}
                        />
                    </div>
                    <div className="input-group job-date-input py-1">
                        <span className="input-group-text login-span">Password</span>
                        <input 
                            type="password" 
                            className="form-control" 
                            name="password"
                            value={credentials.password}
                            onChange={(e) => onChange(e)}
                            onKeyDown={handleKeyPress}
                            
                        />
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="register-account pt-1">
                                <span>Need an account? <a className="register-link" data-bs-toggle="modal" data-bs-target="#registerModal" data-bs-dismiss="modal" >REGISTER</a> today.</span>
                            </div>

                        </div>
                        <div className="col-6">
                            <div className="forgot-login pt-1">
                                <span>Forgot <a className="forgot-link" data-bs-toggle="modal" data-bs-target="#passwordResetModal" data-bs-dismiss="modal" >PASSWORD</a>?</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="modal-footer pt-0 justify-content-center">
                    <button type="button" className="btn login-button login-close" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn login-button modal-save" data-bs-dismiss="modal" onClick={submitLoginInfo}>Login</button>
                </div>
                </div>
            </div>
        </div>  
        <RequestPasswordReset/>
        <RegisterModal/>
    </>
    )    
}