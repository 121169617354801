import React, { useContext } from "react";

// Section for Utilities and Context
import './profilecard.css';
import { AuthContext } from "../utils/AuthContext";
import portrait1 from "../assets/portrait-1.png";
import portrait2 from "../assets/portrait-2.png";
import portrait3 from "../assets/portrait-3.png";
import portrait4 from "../assets/portrait-4.png";
import portrait5 from "../assets/portrait-5.png";
import portrait6 from "../assets/portrait-6.png";

// Section for Components


export default function ProfileCard(props) {
    const { auth, setAuth } = useContext(AuthContext);
    const GOVNA_EDITOR = process.env.REACT_APP_GOVNA_EDITOR;
    const canEdit = auth.user.user_id === props.user_id || auth.user.user_id === GOVNA_EDITOR;

    const randomDefaultImage = () => {
        const defaultPicture = [portrait1, portrait2, portrait3, portrait4, portrait5, portrait6];
        const randomIndex = Math.floor(Math.random() * defaultPicture.length);
        return defaultPicture[randomIndex];
    }

    return (
        <>
            <div className="card profile-card m-0">
                <img src={props.profile_picture || randomDefaultImage()} 
                className="profile-card-image" alt={"Photo of " + props.firstName + " " + props.lastName}/>
                <div className="profile-card-name-div">
                    <h5 className="profile-card-name display-6 ms-3 my-2"><strong>{props.firstName + " " + props.lastName}</strong>
                    {props.pronouns !== "" && props.pronouns !== null && 
                        (<span className="pronouns ms-1">{"(" + props.pronouns + ")"}</span>)}
                    </h5>
                    
                </div>
                <div className="card-body profile-card-body pt-1">
                    
                    <span className="profile-card-title"><i className="bi bi-briefcase"> </i><i>{props.title}</i></span><br/>
                    <hr className="my-2"/>
                    <a className="profile-card-org" href={props.website} target="_blank"><i className="bi bi-buildings"> </i>{props.organization}</a><br/>
                    <span className="profile-card-address ms-3 ps-2">{props.address}
                        {props.suite_number && (
                            <span>, {props.suite_number}</span>
                        )}
                    </span><br/>
                    <span className="profile-card-csz ms-3 ps-2">{props.city}, {props.stateCode} {props.zipCode}</span>
                    <hr className="my-2"/>
                    <a href={"mailto:" + props.email} className="profile-card-email"><i className="bi bi-envelope-at"> </i>{props.email}</a>
                    <p className="profile-card-phone mb-0"><i className="bi bi-telephone"> </i>{props.phone}</p>
                <div className="pci-div pt-2">
                    <div className="row">
                        <div className="col-10">
                            {props.linkedin_url && (
                                <a className="socials-link" href={props.linkedin_url} target="_blank"><i className="bi bi-linkedin pci-socials me-2" /></a>
                            )}
                            {props.facebook_url && (
                                <a className="socials-link" href={props.facebook_url} target="_blank"><i className="bi bi-facebook pci-socials me-2" target="_blank"/></a>
                            )}
                            {props.youtube_url && (
                                <a className="socials-link" href={props.youtube_url} target="_blank"><i className="bi bi-youtube pci-socials me-2" target="_blank"/></a>
                            )}
                            {props.threads_url && (
                                <a className="socials-link" href={props.threads_url} target="_blank"><i className="bi bi-threads pci-socials me-2" target="_blank"/></a>
                            )}
                            {props.twitter_url && (
                                <a className="socials-link" href={props.twitter_url} target="_blank"><i className="bi bi-twitter-x pci-socials me-2" target="_blank"/></a>
                            )}
                            {props.instagram_url && (
                                <a className="socials-link" href={props.instagram_url} target="_blank"><i className="bi bi-instagram pci-socials me-2" target="_blank"/></a>
                            )}
                            {props.tiktok_url && (
                                <a className="socials-link" href={props.tiktok_url} target="_blank"><i className="bi bi-tiktok pci-socials me-2"/></a>
                            )}
                        </div>
                        <div className="col-1 me-0">
                            {canEdit && (
                                <i className="bi bi-pencil-square edit-icon-pci ms-2" alt="Edit PCI Button" data-bs-toggle="modal" data-bs-target="#editPCIModal" />
                            )}
                        </div>

                    </div>
                    
                </div>

                </div>
            </div>
           
        </>
    )
}