import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

import "./notfound.css";
import background from "../assets/eg-img-6.png";

export default function NotFound() {
    const navigate = useNavigate();

    const submitReturn = () => {
        navigate("/public-square");
    }

    return (
        <>
            <div className="notfound-container" >
                <img src={background} className="notfound-background"/>
                <span className="notfound-text display-6">ERROR 404: This page is not in session.</span>
                <span className="notfound-return display-6 mt-3" onClick={submitReturn}>RETURN TO SESSION</span>

            </div>
            
                 
        </>
    )
}