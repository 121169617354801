import React, {useState, useContext, useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import axios from "axios";

// Section for Utilities and Context
import "./layout.css";
import API from "../utils/API";
import { AuthContext } from "../utils/AuthContext";

// Section for Components
import LoginModal from "../components/LoginModal";


// Main App function
export default function Layout() {
    const [showLoginModal, setShowLoginModal] = useState(false);
    const { auth, setAuth } = useContext(AuthContext);
    const [loggingOut, setLoggingOut] = useState(false);
    const navigate = useNavigate();

    const closeCanvas = () => {
        // handlePageAccess();
        document.querySelector('[data-bs-dismiss="offcanvas"]').click();
    }

    const handleLoginClick = () => {
        setShowLoginModal(true); 
    }

    const handleLogoutClick = () => {
        try {
            setAuth({
                isAuthenticated: false,
                loading: false,
                token: null,
                user: null,
            });

            setLoggingOut(true);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!auth.isAuthenticated && loggingOut) {
            navigate('/');
            setLoggingOut(false);
        }
    }, [auth.isAuthenticated, loggingOut, navigate]);

    const handlePageAccess = (page) => {
        closeCanvas();

        if (!auth.isAuthenticated && page !== "/public-square"){
            navigate("/");
            alert("Access Restricted. Please login.");
        } else {
            navigate(page);
        }
    }

    const handleProfileAccess = async (page) => {
        closeCanvas();
        const profileId = await checkUserProfile();

        if (!auth.isAuthenticated){
            navigate("/");
            alert("Access Restricted. Please login.");
        } else if (profileId === undefined) {
            navigate("/profile/create");
        }else {
            navigate(page + profileId);
        }

    }

    const checkUserProfile = async () => {
        let profileId;
        try {
            const res = await API.getProfileUserCheck();
            
            for(let i = 0; i < res.data.length; i++){
                if (res.data[i].user_id === auth.user.user_id){
                    profileId = res.data[i].profile_id;
                    break;
                }
            }

          } catch (error) {
            console.error(error);
        }
        
        return profileId;
    }


  return (
    <>
        {/* <!-- NAVIGATION BAR --> */}
        <nav className="navbar shadow-sm fixed-top">
            {/* <!-- Navbar content --> */}
            <div className="nav-item ms-3">

                <button className="nav-link" href="#" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"  aria-controls="offcanvasNavbar" aria-label="Toggle navigation"><i className="bi bi-list"></i>
                </button>

                <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-header mx-3">
                        <div className="nav-search-div">
                        </div>
                        
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>

                    <div className="offcanvas-body mx-3">
                        <nav>
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li><span className="nav-link fs-5" onClick={() => handlePageAccess("/public-square")}><i className="bi bi-newspaper"></i> Public Square</span></li>
                                <li><span className="nav-link fs-5" onClick={() => handlePageAccess("/directory")}><i className="bi bi-person-vcard"></i> Directory</span></li>
                                <li><span className="nav-link fs-5" onClick={() => handleProfileAccess("/profile/")}><i className="bi bi-person-fill"></i> Profile</span></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="offcanvass-footer p-3">
                        <a className="feedback-link" href="mailto:info@ellogovna.com">Feedback</a>
                    </div>
                </div>
            </div>
            <div className="brand-div">
                <a className="govna-brand display-6" onClick={() => handlePageAccess("/public-square")}><i className="ello">'ello</i><span className="govna-script">Govna</span></a>
            </div>
            

        <div>
            <div className="me-4 account-icons">
                <a className="user-account" onClick={() => handleProfileAccess("/profile/")}><i className="bi bi-person-circle"></i></a>
                {auth.isAuthenticated ? (
                        // If user is logged in, show logout icon
                        <a className="account-logout ms-3" onClick={handleLogoutClick}><i className="bi bi-box-arrow-right"></i></a>
                    ) : (
                        // If user is not logged in, show login icon
                        <a className="account-logout ms-3" data-bs-toggle="modal" data-bs-target="#loginModal" onClick={handleLoginClick}><i className="bi bi-box-arrow-in-left"></i></a>
                    )}
            </div>
        </div>
    
        </nav>

        <Outlet />
        {showLoginModal && <LoginModal />}
    </>
  );
}
