import React, {useState, useEffect, useRef} from "react";

// Section for Utilities and Context
import API from "../utils/API";
import './editJobModal.css';

// Section for Components


// MAIN FUNCTION
export default function EditJobModal(props) {
    const [isCurrentPosition, setIsCurrentPosition] = useState(false);
    const [orgInteracted, setOrgInteracted] = useState(false);
    const [divInteracted, setDivInteracted] = useState(false);
    
    const [titleEntry, setTitleEntry] = useState("");
    const [addressEntry, setAddressEntry] = useState("");
    const [suiteEntry, setSuiteEntry] = useState("");
    const [cityEntry, setCityEntry] = useState("");
    const [stateEntry, setStateEntry] = useState("");
    const [zipCodeEntry, setZipCodeEntry] = useState(null);
    const [startDateEntry, setStartDateEntry] = useState("");
    const [endDateEntry, setEndDateEntry] = useState("");
    
    const [organizations, setOrganizations] = useState([]);
    const [organizationSearchTerm, setOrganizationSearchTerm] = useState("");
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
    const [organizationSelected, setOrganizationSelected] = useState(false);
    const [highlightedOrganizationIndex, setHighlightedOrganizationIndex] = useState(-1);

    const [divisions, setDivisions] = useState([]);
    const [divisionSearchTerm, setDivisionSearchTerm] = useState("");
    const [selectedDivisionId, setSelectedDivisionId] = useState(null);
    const [divisionSelected, setDivisionSelected] = useState(false);
    const [highlightedDivisionIndex, setHighlightedDivisionIndex] = useState(-1);

    const [isFormValid, setFormValid] = useState(false);
    const dropdownRef = useRef(null);
    const dropdownLength = 5;

    useEffect(() => {
        // Set initial state when props.data changes
        if (props.data) {
            setTitleEntry(props.data.title || "");
            setAddressEntry(props.data.street_address || props.data.Division?.street_address || props.data.Organization?.street_address || "");
            setSuiteEntry(props.data.suite_number || props.data.Division?.suite_number || props.data.Organization?.suite_number || "");
            setCityEntry(props.data.city || props.data.Division?.city || props.data.Organization?.city || "");
            setStateEntry(props.data.state || props.data.Division?.state || props.data.Organization?.state || "");
            setZipCodeEntry(props.data.zip_code || props.data.Division?.zip_code || props.data.Organization?.zip_code || null);
            setStartDateEntry(props.data.start_date ? props.data.start_date.slice(0, 10) : "");
            setEndDateEntry(props.data.end_date ? props.data.end_date.slice(0, 10) : "");
            setOrganizationSearchTerm(props.data.Organization?.organization_name || "");
            setSelectedOrganizationId(props.data.Organization?.organization_id || null);
            setDivisionSearchTerm(props.data.Division?.division_name || "");
            setSelectedDivisionId(props.data.Division?.division_id || null);
            setIsCurrentPosition(props.data.current_job)
        }
    }, [props.data]);

    // useEffect TO CHECK INPUTS TO ENABLE / DISABLE 'Add Job' BUTTON
    useEffect(() => {
        if (titleEntry && selectedOrganizationId && addressEntry && cityEntry && stateEntry && zipCodeEntry &&  startDateEntry) {
            if (isCurrentPosition || endDateEntry) {
                setFormValid(true);
            }
        } else {
            setFormValid(false);
        }
    }, [titleEntry, selectedOrganizationId, addressEntry, cityEntry, stateEntry, zipCodeEntry, startDateEntry, isCurrentPosition, endDateEntry]); 

    // HANDLER FOR ADDING JOB EXPERIENCE TO DATABASE
    const updateJobInputs = async () => {
        // e.preventDefault();
        
        try {
            const data = {
                job_id: props.data.job_id,
                title: titleEntry,
                organization_id: selectedOrganizationId,
                address: addressEntry,
                suite_number: suiteEntry,
                city: cityEntry,
                state: stateEntry,
                zip_code: zipCodeEntry,
                start_date: startDateEntry,
                current_job: isCurrentPosition
            }

            if (selectedDivisionId) {
                data.division_id = selectedDivisionId;
            }
            
            if (endDateEntry) {
                data.end_date = endDateEntry;
            }
            
            const response = await API.updateJobExperience(data);
            props.reRenderProfile();
            console.log("Job experience updated successfully:", response.data);
            clearAddJobModal();

        } catch(error) {
           console.error("Server request failed: ",error);
        }
        
    };

    // HANDLER FOR DELETING A JOB RECORD
    const handleDeleteJob = async () => {
        try {
            const data = {
                job_id: props.data.job_id
            }
            
            const response = await API.deleteJobExperience(data);
            props.reRenderProfile();
            console.log("Job experience deleted successfully:", response.data);

        } catch(error) {
           console.error("Server request failed: ",error);
        }
        
    };

    // API IMPORT AND HANDLERS OF ORGANIZATIONS DATA
    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const response = await API.getOrganizations();
                const filteredOrganizations = response.data.filter(org =>
                    org.organization_name.toLowerCase().includes(organizationSearchTerm.toLowerCase())
                );
                setOrganizations(filteredOrganizations.slice(0,5));
            } catch (error) {
                console.error("Error fetching organizations:", error);
            }
        };

        if (organizationSearchTerm !== "") {
            fetchOrganizations();
        } else {
            setOrganizations([]);
        }
    }, [organizationSearchTerm, organizationSelected]);
    
    const handleOrganizationClick = (orgName, orgId) => {
        setOrganizationSearchTerm(orgName);
        setSelectedOrganizationId(orgId);
        setOrganizationSelected(true);
        setHighlightedOrganizationIndex(-1);
    };

    // HANDLER FOR FETCHING DIVISIONS DATA
    const fetchDivisions = async () => {
        try {
            const response = await API.getDivisionsByOrgId(selectedOrganizationId);
            const filteredDivisions = response.data.filter(org =>
                org.division_name.toLowerCase().includes(divisionSearchTerm.toLowerCase())
            );
            setDivisions(filteredDivisions.slice(0,5));
        } catch (error) {
            console.error("Error fetching divisions:", error);
        }

    };
    
    const handleDivisionClick = (divName, divId) => {
        setDivisionSearchTerm(divName);
        setSelectedDivisionId(divId);
        setDivisionSelected(true);
        setHighlightedDivisionIndex(-1);
    };

    // DROPDOWN HEIGHT
    const calculateDropdownHeight = () => {
        const optionHeight = 35; 
        const maxHeight = optionHeight * dropdownLength;
        return `${maxHeight}px`;
    };

    // END DATE / CURRENT POSITION HANDLERS
    const handleCheckboxChange = (e) => {
        setIsCurrentPosition(e.target.checked);

        // Reset the end date when checkbox is checked
        if (e.target.checked) {
          setEndDateEntry("");
        }
    };
    
    const handleEndDateChange = (e) => {
        setEndDateEntry(e.target.value);
    };

    // Function to convert a string to proper case
    const toProperCase = (str) => {
        return str.toLowerCase().replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
    };

    // HANDLER FOR CLEARED ORGANIZATION INPUT
    const handleOrganizationInputChange = (e) => {
        setOrganizationSearchTerm(e.target.value);
        if (!orgInteracted) {
            setOrgInteracted(true);
            // setOrganizationSelected(false);
        }
    };

    
    // HANDLER FOR CLEARED DIVISION INPUT
    const handleDivisionInputChange = (e) => {
        setDivisionSearchTerm(e.target.value);
        fetchDivisions();
        if (!divInteracted) {
            setDivInteracted(true);
            // setDivisionSelected(false);
        }

        if (divisionSearchTerm === "") {
            setDivisions([]);
        } 
    };

    // HANDLER FOR NAVIGATING DROPDOWN MENU
    const handleOrganizationKeyDown = (e) => {
        if (e.key === "ArrowUp") {
            e.preventDefault();
            setHighlightedOrganizationIndex(prevIndex => Math.max(prevIndex - 1, 0));
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            setHighlightedOrganizationIndex(prevIndex => Math.min(prevIndex + 1, dropdownLength - 1));
        } else if (e.key === "Enter" && highlightedOrganizationIndex !== -1) {
            handleOrganizationClick(
                organizations[highlightedOrganizationIndex].organization_name,
                organizations[highlightedOrganizationIndex].organization_id
            );
        }
    };

    // HANDLER FOR NAVIGATING DROPDOWN MENU
    const handleDivisionKeyDown = (e) => {
        if (e.key === "ArrowUp") {
            e.preventDefault();
            setHighlightedDivisionIndex(prevIndex => Math.max(prevIndex - 1, 0));
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            setHighlightedDivisionIndex(prevIndex => Math.min(prevIndex + 1, dropdownLength - 1));
        } else if (e.key === "Enter" && highlightedDivisionIndex !== -1) {
            handleDivisionClick(
                divisions[highlightedDivisionIndex].division_name,
                divisions[highlightedDivisionIndex].division_id
            );
        }
    };

    const clearAddJobModal = () => {
        setTitleEntry("");
        setAddressEntry("");
        setSuiteEntry("");
        setCityEntry("");
        setStateEntry("");
        setZipCodeEntry("");
        setOrganizationSearchTerm("");
        setSelectedOrganizationId(null);
        setOrganizationSelected(false);
        setDivisionSearchTerm("");
        setSelectedDivisionId(null);
        setDivisionSelected(false);
        setStartDateEntry("");
        setEndDateEntry("");
        setIsCurrentPosition(false);
    };


    // MAIN RETURNED CODE
    return (
        <>
            {/* Modal to edit the content of the user's bio */}
            <div className="modal fade edit-job-modal" id="editJobModal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="editJobModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header edit-job-modal-header">
                            <h5 className="modal-title edit-job-modal-title display-6">Edit Job Experience</h5>
                        </div>
                        <div className="modal-body">
                            <div className="input-group py-1">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    // placeholder={titleEntry}
                                    value={titleEntry}
                                    onChange={(e) => {setTitleEntry(e.target.value)}} 
                                    required
                                />
                            </div>
                            <div className="input-group py-1">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Company" 
                                    value={organizationSearchTerm} 
                                    onChange={handleOrganizationInputChange} 
                                    onKeyDown={handleOrganizationKeyDown}
                                    required
                                />
                            </div>
                            {orgInteracted && !organizationSelected && organizations.length > 0 && (
                                <select className="form-select" size="5"
                                        style={{ maxHeight: calculateDropdownHeight(), overflowY: "auto" }}
                                        ref={dropdownRef} >
                                    {organizations.map((org, index) => (
                                        <option key={org.organization_id} 
                                        className={`dropdown-item add-job-option ${index === highlightedOrganizationIndex ? "active-option" : ""}`}
                                        onClick={() => handleOrganizationClick(org.organization_name, org.organization_id)}>{org.organization_name}<br/></option>
                                    ))}
                                </select>
                            )}
                            <div className="input-group py-1">
                            <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Office / Division" 
                                    value={divisionSearchTerm} 
                                    onChange={handleDivisionInputChange} 
                                    onKeyDown={handleDivisionKeyDown}
                                    required
                                />
                            </div>
                            {divInteracted && !divisionSelected  && divisions.length > 0 && (
                                <select className="form-select" size="5" 
                                        style={{ maxHeight: calculateDropdownHeight(), overflowY: "auto" }} 
                                        ref={dropdownRef} >
                                    {divisions.map((div, index) => (
                                        <option key={div.division_id} 
                                        className={`dropdown-item add-job-option ${index === highlightedDivisionIndex ? "active-option" : ""}`}
                                        onClick={() => handleDivisionClick(div.division_name, div.division_id)}>{div.division_name}<br/></option>
                                    ))}
                                </select>
                            )}
                            <div className="input-group py-1">
                                <div className="row address-row">
                                    <div className="col-9 px-0 me-0">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Address"
                                            value={addressEntry || ""}
                                            onChange={(e) => {setAddressEntry(toProperCase(e.target.value))}}
                                        />
                                    </div>
                                    <div className="col-3 pe-0 me-0">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Suite #"
                                            value={suiteEntry || ""}
                                            onChange={(e) => {setSuiteEntry(e.target.value)}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group py-1">
                                <div className="row ">
                                    <div className="col-6 pe-0 me-0">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="City"
                                            value={cityEntry || ""}
                                            onChange={(e) => {setCityEntry(toProperCase(e.target.value))}}
                                        />
                                    </div>
                                    <div className="col-3 pe-0 me-0">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="State"
                                            value={stateEntry || ""}
                                            onChange={(e) => {setStateEntry(e.target.value)}}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            aria-label="" 
                                            placeholder="ZIP"
                                            value={zipCodeEntry || undefined}
                                            onChange={(e) => {setZipCodeEntry(e.target.value)}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 pe-0 me-0">
                                    <div className="input-group input-group-sm job-date-input py-1">
                                        <span className="input-group-text date-span">Start date</span>
                                        <input 
                                            type="date" 
                                            className="form-control" 
                                            aria-label=""  
                                            value={startDateEntry}
                                            onChange={(e) => {setStartDateEntry(e.target.value)}}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="input-group input-group-sm job-date-input py-1">
                                        <span className="input-group-text date-span">End date</span>
                                        <input 
                                            type="date" 
                                            className="form-control" 
                                            aria-label="" 
                                            value={endDateEntry} 
                                            onChange={handleEndDateChange} 
                                            disabled={isCurrentPosition}
                                        />
                                    </div>
                                    <div className="form-check form-check-reverse current-pos-check ms-2 pt-1">
                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={isCurrentPosition} onChange={handleCheckboxChange}/>
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Current Position
                                        </label>
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                        <div className="modal-footer">
                            {!isCurrentPosition && (
                                <div 
                                    className="job-delete" 
                                    data-bs-dismiss="modal" 
                                    alt="Button to close modal"
                                    onClick={handleDeleteJob}
                                ><i className="bi bi-trash3"/></div>
                            )}
                            
                            <button 
                                type="button" 
                                className="btn modal-close" 
                                data-bs-dismiss="modal" 
                                alt="Button to close modal" 
                            >Close</button>
                            <div className={isFormValid ? "" : "disabled-button"}>
                                <button 
                                    type="button" 
                                    className="btn modal-save" 
                                    data-bs-dismiss="modal" 
                                    alt="Button to save changes to bio"
                                    onClick={updateJobInputs}
                                    disabled={!isFormValid}
                                >Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}