import React, {useState, useEffect, useContext} from "react";

// Section for Utilities and Context
import "./publicsquare.css";
import API from "../utils/API";
import { AuthContext } from "../utils/AuthContext";

// Section for Components
import PostCard from "../components/PostCard";
import HeadlineFeed from "../components/HeadlineFeed";
import CreatePost from "../components/CreatePost";
import LoginModal from "../components/LoginModal";
import AdComponent from "../components/AdComponent";

export default function PublicSquare() {
    const { auth, setAuth } = useContext(AuthContext);
    const [searchQuery, setSearchQuery] = useState("");
    const [filterSelect, setFilterSelect] = useState('');
    const [displayedResults, setDisplayedResults] = useState(20);
    const [isFetching, setIsFetching] = useState(false);
    const [allPosts, setAllPosts] = useState([]); 
    const [postTypeSelect, setPostTypeSelect] = useState(undefined);
    const [postTypesList, setPostTypesList] = useState([]);
    const [recentSelect, setRecentSelect] = useState(true);
    const [popularSelect, setPopularSelect] = useState(false);
    const [randomSelect, setRandomSelect] = useState(false);
    const [loading, setLoading] = useState(false); 
    const [activeSection, setActiveSection] = useState('posts');
    
    // GET SELECT PROFILE INFO FROM DATABASE FOR DIRECTORY CARDS
    useEffect(() => {

        const fetchPosts = async () => {

            try {
                setLoading(true);
                const data = await API.getPosts();
                setAllPosts(data.data);
                setLoading(false);
            } catch (error) {
                console.log("Error fetching post info:", error);
            }   
        };

        const fetchAllPostTypes = async () => {        
            try {
                const data = await API.getAllPostTypes(); 
                setPostTypesList(data.data);
            } catch (error) {
                console.log("Error fetching post type info:", error);
            }
        };

        fetchPosts();
        fetchAllPostTypes();
    }, []);

    // FUNCTION TO REFRESH POSTS
    const refreshPosts = async () => {        
        try {
            const data = await API.getPosts(); 
            setAllPosts(data.data);
        } catch (error) {
            console.log("Error fetching single profile:", error);
        }
    };

    // Infinite scroll handler
    const handleScroll = () => {
        const lastCard = document.querySelector(".public-square-middle-column > :last-child");

        if (lastCard) {
            const lastCardRect = lastCard.getBoundingClientRect();

            if (lastCardRect.bottom <= window.innerHeight && !isFetching) {
                setIsFetching(true);
                setDisplayedResults((prev) => prev + 10);
            }
        }
    };
      
    // Add an event listener in the useEffect hook
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [displayedResults]);

    // 
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 770) {
                // Reset activeSection to null (or any other default) when on desktop
                setActiveSection(null);
            }
        };
    
        // Add event listener to handle window resize
        window.addEventListener('resize', handleResize);
    
        // Call handleResize initially to set the correct state based on current screen size
        handleResize();
    
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Function to handle sorting by date (newest first)
    const sortByRecent = () => {
        const sortedPosts = [...allPosts].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setRecentSelect(true);
        setPopularSelect(false);
        setRandomSelect(false);
        setAllPosts(sortedPosts);
        
    };

    // Function to handle sorting by likes (most popular first)
    const sortByPopular = () => {
        const sortedPosts = [...allPosts].sort((a, b) => b.likes_count - a.likes_count);
        setRecentSelect(false);
        setPopularSelect(true);
        setRandomSelect(false);
        setAllPosts(sortedPosts);
    };

    // Function to handle random sorting
    const sortByRandom = () => {
        const sortedPosts = [...allPosts].sort(() => Math.random() - 0.5);
        setRecentSelect(false);
        setPopularSelect(false);
        setRandomSelect(true);
        setAllPosts(sortedPosts);
    };

    // Filtering through the imported results, newest first. Make sure to update source once connecting to API / database
    const searchPosts = allPosts.sort().filter(post => {
        const searchParts = searchQuery.toLowerCase().split(' ');
    
        const matchesSearch = searchParts.every(part =>
            post.post_title.toLowerCase().includes(part) ||
            post.ProfileInfo.first_name.toLowerCase().includes(part) ||
            post.ProfileInfo.last_name.toLowerCase().includes(part) ||
            post.post_body.toLowerCase().includes(part)
            // || post.organization.toLowerCase().includes(part)
        );
    
        // const matchesFilter = filterSelect === "" || post.tags === filterSelect || 
        //     (Array.isArray(post.tags) && post.tags.some(tags => filterSelect.includes(tags))
        // );

        const postTypeSelectAsInt = parseInt(postTypeSelect, 10);

        const matchesType = postTypeSelect === undefined || post.post_type === postTypeSelectAsInt;
    
        return matchesSearch && matchesType;
    });

    // Function to handle clearing filters and search
    const handleResetFilters = () => {
        sortByRecent();
        setSearchQuery("");
        setPostTypeSelect(undefined);
    };
    
    return (
        <>
           <div className="row public-square-row">
                <div className="mobile-toggle-buttons d-block d-lg-none mb-3">
                    <div className="mobile-toggle-div">
                        <button 
                            className={`${activeSection === 'posts' ? 'selected-mobile-feed' : 'nonselected-mobile-feed'}`} 
                            onClick={() => setActiveSection('posts')}
                        >
                            Posts
                        </button>
                        <button 
                            className={`${activeSection === 'headlines' ? 'selected-mobile-feed' : 'nonselected-mobile-feed'}`} 
                            onClick={() => setActiveSection('headlines')}
                        >
                            Headlines
                        </button>
                    </div>
                </div>

                {(activeSection === 'posts') && ( 
                    <>
                        <div className="col-md-10 col-sm-12 public-square-left-column">
                            {/* search posts feature */}
                            <div className="form-floating posts-search-div z-3 mb-3">
                                <input className="form-control border-0 border-bottom posts-search" id="search-posts" type="text" placeholder="Search Posts" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                <label className="posts-search-label" htmlFor="search-posts">Search Posts</label>
                            </div>
                            <div className="public-square-buttons">
                                {/* insert post creation modal button */}
                                <button 
                                    className="btn create-post-button mb-2" 
                                    data-bs-toggle="modal" 
                                    data-bs-target={auth.user?.user_id ? "#createPostModal" : "#loginModal"}
                                >Create Post</button>
                                
                                {/* insert buttons for sorting 'Recent', 'Popular', and 'Random' */}
                                <div className="row posts-filter-div">
                                    <button className={`${recentSelect ? "selected-posts-filter" : "posts-filter-button"}`} onClick={sortByRecent}><i className="bi bi-clock"> </i>Recent</button>
                                    <button className={`${popularSelect ? "selected-posts-filter" : "posts-filter-button"}`} onClick={sortByPopular}><i className="bi bi-fire"> </i>Popular</button>
                                    <button className={`${randomSelect ? "selected-posts-filter" : "posts-filter-button"}`} onClick={sortByRandom}><i className="bi bi-shuffle"> </i>Random</button>   
                                </div>

                                <div className="row posts-filter-div">
                                    <select className="form-select post-type-filter-select" value={postTypeSelect !== undefined ? postTypeSelect : ""} onChange={(e) => setPostTypeSelect(e.target.value)}>
                                    <option value="" disabled>Filter by Type</option>
                                    {postTypesList.map(postType => (
                                        <option key={postType.posttype_id} value={postType.posttype_id}>{postType.post_type}</option>
                                    ))}
                                    </select>
                                    <button className="clear-posts-filter-button" onClick={handleResetFilters}>Reset</button>
                                </div>
                                
                                <div className="ad-wrapper-ps-left mt-2">
                                    <AdComponent/>
                                </div>
                                
                                
                            </div>
                            {/* Top Posts feature */}
                            {/* <text>&copy; <i>'ello</i>Govna 2024.</text> */}
                        </div>

                        <div className="col-md-10 col-sm-12 public-square-middle-column">                    

                            <div className="row posts-feed mt-4">
                                {/* put posts component here */}
                                {searchPosts.slice(0, displayedResults).sort().map((post, index) => (
                                    // Pass post data as props to UserCard component
                                    <PostCard 
                                        key={index}
                                        post_id={post.post_id}
                                        title={post.post_title}
                                        author={post.ProfileInfo ? post.ProfileInfo.first_name + " " + post.ProfileInfo.last_name : "Anonymous"}
                                        author_id={post.post_author}
                                        post_type={post.post_type}
                                        body={post.post_body}
                                        org_id={post.organization_id}
                                        div_id={post.division_id}
                                        postDate={post.created_at}
                                        likes={post.likes_count}
                                        reads={post.reads}
                                        comments={post.PostComments}
                                        user_id={post.ProfileInfo.user_id}
                                        event_location={post.event_location}
                                        event_date={post.event_date}
                                        event_start={post.event_start}
                                        event_end={post.event_end}
                                        event_type={post.event_type}
                                        refresh={refreshPosts}
                                    />
                                ))}
                            </div>
                            {loading && allPosts.length === 0 && (
                                <div className="d-flex position-absolute top-50 start-50 translate-middle ">
                                    <div className="spinner-border loading-spinner" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}

                {(activeSection === null) && ( 
                    <>
                        <div className="col-xl-3 col-lg-3 col-md-10 col-sm-12 public-square-left-column">
                            {/* search posts feature */}
                            <div className="form-floating posts-search-div z-3 mb-3">
                                <input className="form-control border-0 border-bottom posts-search" id="search-posts" type="text" placeholder="Search Posts" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                <label className="posts-search-label" htmlFor="search-posts">Search Posts</label>
                            </div>
                            <div className="public-square-buttons">
                                {/* insert post creation modal button */}
                                <button 
                                    className="btn create-post-button mb-2" 
                                    data-bs-toggle="modal" 
                                    data-bs-target={auth.user?.user_id ? "#createPostModal" : "#loginModal"}
                                >Create Post</button>
                                
                                {/* insert buttons for sorting 'Recent', 'Popular', and 'Random' */}
                                <div className="row posts-filter-div">
                                    <button className={`${recentSelect ? "selected-posts-filter" : "posts-filter-button"}`} onClick={sortByRecent}><i className="bi bi-clock"> </i>Recent</button>
                                    <button className={`${popularSelect ? "selected-posts-filter" : "posts-filter-button"}`} onClick={sortByPopular}><i className="bi bi-fire"> </i>Popular</button>
                                    <button className={`${randomSelect ? "selected-posts-filter" : "posts-filter-button"}`} onClick={sortByRandom}><i className="bi bi-shuffle"> </i>Random</button>   
                                </div>

                                <div className="row posts-filter-div">
                                    <select className="form-select post-type-filter-select" value={postTypeSelect !== undefined ? postTypeSelect : ""} onChange={(e) => setPostTypeSelect(e.target.value)}>
                                    <option value="" disabled>Filter by Type</option>
                                    {postTypesList.map(postType => (
                                        <option key={postType.posttype_id} value={postType.posttype_id}>{postType.post_type}</option>
                                    ))}
                                    </select>
                                    <button className="clear-posts-filter-button" onClick={handleResetFilters}>Reset</button>
                                </div>
                                
                                <div className="ad-wrapper-ps-left rounded mt-3">
                                    <AdComponent/>
                                </div>
                                
                            </div>
                            {/* Top Posts feature */}
                            {/* <text>&copy; <i>'ello</i>Govna 2024.</text> */}
                        </div>

                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 public-square-middle-column">                    

                            <div className="row posts-feed mt-4">
                                {/* put posts component here */}
                                {searchPosts.slice(0, displayedResults).sort().map((post, index) => (
                                    // Pass post data as props to UserCard component
                                    <PostCard 
                                        key={index}
                                        post_id={post.post_id}
                                        title={post.post_title}
                                        author={post.ProfileInfo ? post.ProfileInfo.first_name + " " + post.ProfileInfo.last_name : "Anonymous"}
                                        author_id={post.post_author}
                                        post_type={post.post_type}
                                        body={post.post_body}
                                        org_id={post.organization_id}
                                        div_id={post.division_id}
                                        postDate={post.created_at}
                                        likes={post.likes_count}
                                        reads={post.reads}
                                        comments={post.PostComments}
                                        user_id={post.ProfileInfo.user_id}
                                        event_location={post.event_location}
                                        event_date={post.event_date}
                                        event_start={post.event_start}
                                        event_end={post.event_end}
                                        event_type={post.event_type}
                                        refresh={refreshPosts}
                                    />
                                ))}
                            </div>
                            {loading && allPosts.length === 0 && (
                                <div className="d-flex position-absolute top-50 start-50 translate-middle ">
                                    <div className="spinner-border loading-spinner" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-5 col-sm-12 public-square-right-column">
                            <HeadlineFeed />
                        </div>
                    </>
                )}
                
                {(activeSection === 'headlines') && (
                    <div className="col-md-10 col-sm-12 public-square-right-column">
                        <HeadlineFeed />
                    </div>
                )}
            </div>
            <div className="row last-row"/>
            <CreatePost
                refresh={refreshPosts}
            />
            <LoginModal />

        </>
    )
}