import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Section for Utilities and Context
import "./createProfile.css";
import API from "../utils/API";
import { AuthContext } from "../utils/AuthContext";

// Section for Components
import CreateProfileForm from "../components/CreateProfileForm";
import CreateJobForm from "../components/CreateJobForm";
import CreateMiscForm from "../components/CreateMiscForm";
import ClaimProfile from "../components/ClaimProfile";
import dapperDefault from "../assets/portrait-5.png";

export default function CreateProfile() {
    const { auth } = useContext(AuthContext);
    const [alreadyConnectedProfile, setAlreadyConnectedProfile] = useState(false);
    const [checkExisting, setCheckExisting] = useState(true);
    const [basicInfo, setBasicInfo] = useState(false);
    const [currentJobInfo, setCurrentJobInfo] = useState(false);
    const [newBio, setNewBio] = useState(false);
    const [profileId, setProfileId] = useState();
    const [possibleMatches, setPossibleMatches] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const checkUserConnection = async () => {
            const userHasProfile = await checkUserIds();

            if (userHasProfile) {
                console.log("PID on create profile: ", profileId)
                // navigate("/profile/" + profileId);
            } else {
                fetchPossibleMatch();
            }

        };

        checkUserConnection();

        const fetchPossibleMatch = async () => {
            const data = {
                first_name: auth.user.first_name.trim(),
                last_name: auth.user.last_name.trim(),
                email: auth.user.email.trim(),
            };
            const response = await API.getProfileMatch(data);

            setPossibleMatches(response.data);

            if (response.data.length === 0) {
                setCheckExisting(false);
                setBasicInfo(true);
            }
        };

    }, [auth.user.user_id]);

    // Navigate to profile when profileId is set
    useEffect(() => {
        if (alreadyConnectedProfile && profileId) {
            navigate(`/profile/${profileId}`);
        }
    }, [alreadyConnectedProfile, profileId, navigate]);

    const checkUserIds = async () => {
        try {
            const res = await API.getProfileUserCheck();

            for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].user_id === auth.user.user_id) {
                    setAlreadyConnectedProfile(true);
                    const pid = res.data[i].profile_id;

                    console.log("PID on checkUserIds function: ", res.data[i].profile_id)
                    setProfileId(pid);
                    return true; // Return true if profile found
                }
            }

            return false; // Return false if no profile found
        } catch (error) {
            console.error(error);
            return false; // Return false in case of error
        }
    };

    const handleNext = () => {
        if (basicInfo) {
            setBasicInfo(false);
            setCurrentJobInfo(true);
            return;
        }

        if (currentJobInfo) {
            setCurrentJobInfo(false);
            setNewBio(true);
            return;
        }
    };

    const skipProfileClaim = () => {
        setCheckExisting(false);
        setBasicInfo(true);
    };

    return (
        <>
            <div className="row create-profile-row-header mb-4">
                <div className="col-md-4">
                    <h5 className="display-6 create-profile-header mt-3">Welcome to 'elloGovna! Please create your profile.</h5>
                </div>
            </div>
            <div className="row create-profile-row">
                <div className="col-md-4">
                    {checkExisting && (
                        <>
                            <p className="">Before creating a profile, do you see an existing profile for you below?</p>
                            {possibleMatches.map((profile) => (
                                <div className="card match-profile-card mb-3" key={profile.profile_id}>
                                    <div className="row g-0">
                                        <div className="col-md-4">
                                            <img src={profile.profile_picture || dapperDefault} className="img-fluid rounded-start claim-profile-picture" alt={`profile picture for ${profile.first_name} ${profile.last_name}`} />
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h5 className="card-title">{profile.first_name} {profile.last_name}</h5>
                                                {profile.JobExperiences[0]?.title && (
                                                    <span className="card-text"><i>{profile.JobExperiences[0]?.title}</i></span>
                                                )}
                                                
                                                {profile.JobExperiences[0]?.Organization?.organization_name && (
                                                    <span className="card-text">{profile.JobExperiences[0]?.Organization?.organization_name}</span>
                                                )}
                                                
                                                {profile.work_email && (
                                                    <span className="card-text">{profile.work_email}</span>
                                                )}
                                                <br />
                                                <ClaimProfile
                                                    profile_id={profile.profile_id}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="mt-5">
                                <button
                                    type="button"
                                    className="btn create-next-button"
                                    alt="Button to skip profile claim and move to enter profile creation"
                                    onClick={skipProfileClaim}
                                >None are me - Enter profile creation<i className="bi bi-arrow-right ms-1" /></button>
                            </div>
                        </>
                    )}

                    {basicInfo && (
                        <CreateProfileForm
                            handleNext={handleNext}
                            setProfileId={setProfileId}
                        />
                    )}
                    {currentJobInfo && (
                        <CreateJobForm
                            handleNext={handleNext}
                            profileId={profileId}
                        />
                    )}
                    {newBio && (
                        <CreateMiscForm
                            handleNext={handleNext}
                            profileId={profileId}
                        />
                    )}
                </div>
            </div>
            <br />
            <div className="row last-row mt-5" />
        </>
    );
}
