import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// Section for Utilities and Context
import "./postcard.css";
import API from "../utils/API";
import { AuthContext } from "../utils/AuthContext";

// PostCardBody component
// const PostCardBody = ({ body }) => {
//     const MAX_CHARACTERS = 300;
  
//     // Logic to truncate body to maximum characters
//     const truncatedBody = body.length > MAX_CHARACTERS ? `${body.substring(0, MAX_CHARACTERS)}...` : body;
  
//     return (
//       <p className="card-text">{truncatedBody}</p>
//     );
// };


export default function PostComment(props) {
    const { auth, setAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [commentEditing, setCommentEditing] = useState(false);
    const [commentInput, setCommentInput] = useState("");
    const [editCommentInput, setEditCommentInput] = useState("");
    const [profileData, setProfileData] = useState([]);

    // console.log("PostComment props: ", props)


    const handleNavigate = (profile_id) => {
        navigate("/profile/" + profile_id);
    }

    // GET INFO FROM DATABASE FOR SINGLE POST
    useEffect(() => {
        const fetchProfileData = async () => {        
            try {
                const id = auth.user?.user_id;
                if (!id) {
                    console.error("User ID is missing. Unable to fetch profile data.");
                    return;
                }
                const data = await API.getProfileDataForPost(id); 
                // console.log("Fetched Profile Info:", data.data);
                setProfileData(data.data);
            } catch (error) {
                console.log("Error fetching profile info:", error);
            }
        };
        
        fetchProfileData();
        setEditCommentInput(props.comment_body);

    }, [auth.user?.user_id, props]); 


    const updateComment = async (id) => {
        try {
            const data = {
                comment_id: id,
                comment_body: editCommentInput
            } 

            const response = await API.updateComment(data);
            props.refresh();
            console.log("Post updated successfully:", response.data);
            
        } catch(error) {
            console.error("Server request failed: ",error);
        }
    };

    const handleEditComment = (id, body) => {
        setCommentEditing(prevEditComment => !prevEditComment);

        if(!commentEditing) {
            setEditCommentInput(body)
        }

        if (commentEditing) {
            updateComment(id);
        }
    };


    // HANDLER FOR DELETING A USER'S COMMENT
    const deleteComment = async (id) => {
        try {
            const data = {
                comment_id: id
            }
            
            const response = await API.deleteComment(data);
            props.refresh();
            console.log("Job experience deleted successfully:", response.data);

        } catch(error) {
           console.error("Server request failed: ",error);
        }        
    };

    return (
        <>    
            <div className="comment-div row " key={"comment" + props.comment_id}>
                    <p className="my-0">
                        <span className="comment-author" data-bs-dismiss="modal" onClick={() => handleNavigate(props.profile_id)}>
                            {props.first_name} {props.last_name}
                        </span>
                        {props.createdAt < props.updatedAt && (
                            <span className="comment-edited-tag">
                                <i> (edited)</i>
                            </span>
                        )}
                                                            
                    </p>
                <div className="col-11">
                    {!commentEditing && (
                        <p className="comment-body mb-1">{props.comment_body}</p>
                    )}
                    {commentEditing && (
                        <input 
                            className="edit-comment-input form-control py-0"
                            value={editCommentInput}                                     
                            onChange={(e) => setEditCommentInput(e.target.value)}
                        ></input>
                        
                    )}
                </div>
                <div className="col-1">
                    {props.user_id === auth.user?.user_id && (
                        <>
                            <i className={`bi edit-comment-icon ${commentEditing === false ? 'bi-pencil-square ' : 'bi-floppy2-fill'}`} onClick={() => handleEditComment(props.comment_id, props.comment_body)}/>
                            <i className="bi bi-trash3 delete-comment-icon ms-4" 
                                onClick={() => deleteComment(props.comment_id)}
                            />
                        </>                                            
                    )}
                    
                </div>
                <hr className="comment-hr my-1"/>
            </div>
        </>
    )
}