import axios from "axios";

const config = { headers: { "Content-Type": "application/json" }};

export default {
  // AUTH APIs
  login: function (data) {
    return axios.post("/auth/login", data, config);
  },

  signup: function (data) {
    return axios.post("/auth/register", data, config);
  },

  fetchApprovedDomains: function () {
    return axios.get("/auth/email-domains");
  },

  fetchBetaEmails: function () {
    return axios.get("/auth/beta-access");
  },

  fetchApprovedEmails: function () {
    return axios.get("/auth/approved-emails");
  },

  confirmRegistraionEmail: function (token) {
    return axios.post("/auth/confirm-registration", {token});
  },

  updatePassword: function (data) {
    return axios.post("/auth/update-password/", data, config);
  },

  captchaVerify: function (data) {
    return axios.post("/auth/verify", data, config);
  },

  requestPasswordReset: function (email, token) {
    return axios.post("/auth/request-password-reset", {email: email, reCaptcha_token: token});
  },

  resetPassword: function (token, password) {
    return axios.post("/auth/reset-password/" + token, {password: password})
  },

  // NEWS APIs
  getNews: function () {
    return axios.get("/api/news");
  },

  // POSTS APIs
  getPosts: function () {
    return axios.get("/api/posts/all");
  },

  getAllPostTypes: function () {
    return axios.get("/api/posts/post-types");
  },

  getAllEventTypes: function () {
    return axios.get("/api/posts/event-types");
  },

  getSinglePostType: function (id) {
    return axios.get("/api/posts/post-type/" + id);
  },

  getSingleEventType: function (id) {
    return axios.get("/api/posts/event-type/" + id);
  },

  addNewPost: function (data) {
    return axios.post("/api/posts/add-post", data);
  },

  updatePost: function (data) {
    return axios.post("/api/posts/update-post", data);
  },

  deletePost: function (data) {
    return axios.delete("/api/posts/delete-post", {data: data});
  },

  addNewComment: function (data) {
    return axios.post("/api/posts/add-comment", data);
  },

  updateComment: function (data) {
    return axios.post("/api/posts/update-comment", data);
  },

  deleteComment: function (data) {
    return axios.delete("/api/posts/delete-comment", {data: data});
  },

  addNewLike: function (data) {
    return axios.post("/api/posts/add-like", data);
  },

  updatePostLikes: function (data) {
    return axios.post("/api/posts/update-likes", data);
  },

  getUserLikes: function (id) {
    return axios.post("/api/posts/get-likes/" + id);
  },

  updateReadCount: function (data) {
    return axios.post("/api/posts/update-read-count", data);
  },

  getProfileDataForPost: function (user_id) {
    return axios.post("/api/posts/profile-info", {data: user_id});
  },
  
  // DIRECTORY APIs
  getDirectoryProfiles: function () {
    return axios.get(`/api/profiles/directory`);
  },

  getProfileMatch: function (data) {
    return axios.post("/api/profiles/new-user-check", data);
  },
  
  // PROFILE APIs
  createProfile: function (data) {
    return axios.post("/api/profiles/create-profile", data);
  },

  getSingleProfile: function (id) {
    return axios.get("/api/profiles/profile/" + id);
  },

  getProfileUserCheck: function () {
    return axios.get("/api/profiles/user-check");
  },

  getOrganizationColleagues: function (data) {
    return axios.post("/api/profiles/organization-colleagues", data)
  },

  getDivisionColleagues: function (data) {
    return axios.post("/api/profiles/division-colleagues", data)
  },

  claimProfile: function (data) {
    return axios.post("/api/profiles/claim/" + data.profile_id, data);
  },

  getOrganizations: function () {
    return axios.get("/api/orgs/");
  },

  getIssueAreas: function () {
    return axios.get("/api/issues/");
  },
  
  addOrganization: function (data) {
    return axios.post("/api/orgs/add-organization", data);
  },
  
  addIssueArea: function (data) {
    return axios.post("/api/issues/add-issue-area", data);
  },
  
  addUserIssue: function (data) {
    return axios.post("/api/issues/add-user-issue", data);
  },

  deleteUserIssue: function (data) {
    console.log("in deleteUserIssue API: ", data)
    return axios.delete("/api/issues/delete-user-issue", {data: data});
  },

  getSingleOrganizationName: function (id) {
    return axios.get("/api/orgs/organization-name/" + id);
  },
  
  addDivision: function (data) {
    return axios.post("/api/orgs/add-division", data);
  },

  getSingleDivisionName: function (id) {
    return axios.get("/api/orgs/division-name/" + id);
  },
  
  getDivisionsByOrgId: function (id) {
    return axios.get("/api/orgs/divisions/" + id);
  },

  addJobExperience: function (data) {
    return axios.post("/api/profiles/add-job/", data);
  },

  uploadProfilePicture: function (data) {
    console.log("in API: ", data)
    return axios.post("/api/profiles/upload-profile-picture", data);
  },

  updateProfileInfo: function (data) {
    return axios.post("/api/profiles/update-profile", data);
  },

  updateJobExperience: function (data) {
    return axios.post("/api/profiles/update-job/", data);
  },

  deleteJobExperience: function (data) {
    return axios.delete("/api/profiles/delete-job", {data: data});
  },

  updateBiography: function (data) {
    return axios.post("/api/profiles/bio/" + data.profile_id, data);
  },
};
