import React, { useEffect } from "react";
import AdSense from "react-adsense";

// Section for Utilities and Context
import "./adbox.css";


export default function AdComponent() {
    useEffect(() => {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
          console.error("AdSense error:", e);
        }
      }, []);

    return (
        <>              
            <div className="ad-div">
                <ins className="adsbygoogle"
                    style={{display:"block"}}
                    data-ad-client="ca-pub-5224667860691835"
                    data-ad-slot="2463910294"
                    data-ad-format="auto"
                    data-full-width-responsive="true">
                </ins>
            </div>
           
        </>
    )
}